import React, {Component} from "react";

class Logo extends Component {
   render(){
     return(
       <>
        <span className="logoJewgeny">J</span>
          {/*<p className="logoFont">Jewgeny</p>*/}
       </>
     )
   }
}

export default Logo;
